import { useMemo } from 'react';
import type { Unit } from '../types/units';

export const useFilteredUnits = (
  units: Unit[],
  filter: string,
  sortBy: 'aptNumber' | 'moveInDate'
) => {
  return useMemo(() => {
    return units
      .filter(unit =>
        Object.values(unit).some(value =>
          String(value || '').toLowerCase().includes(filter.toLowerCase())
        )
      )
      .sort((a, b) => {
        if (sortBy === 'aptNumber') {
          // Handle null/undefined values for aptNumber
          const aNum = a.aptNumber || '';
          const bNum = b.aptNumber || '';
          return aNum.localeCompare(bNum, undefined, { numeric: true });
        }
        // Handle null/undefined values for moveInDate
        const aDate = a.moveInDate ? new Date(a.moveInDate).getTime() : 0;
        const bDate = b.moveInDate ? new Date(b.moveInDate).getTime() : 0;
        return aDate - bDate;
      });
  }, [units, filter, sortBy]);
};

export default useFilteredUnits;