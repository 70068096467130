import React from 'react';
import { RefreshCw, KeyRound, Package } from 'lucide-react';
import type { KeyLog } from '../../types/keys';

interface ArchivedKeyLogsProps {
  logs: KeyLog[];
  searchQuery: string;
  sortBy: 'apt_number' | 'checkout_time';
  onRevert: (id: string) => Promise<void>;
}

const ArchivedKeyLogs: React.FC<ArchivedKeyLogsProps> = ({
  logs,
  searchQuery,
  sortBy,
  onRevert
}) => {
  const [revertingId, setRevertingId] = React.useState<string | null>(null);

  const filteredAndSortedLogs = React.useMemo(() => {
    return logs
      .filter(log => {
        const searchTerm = searchQuery.toLowerCase();
        return log.type === 'key'
          ? log.apt_number.toLowerCase().includes(searchTerm) ||
            log.checked_out_by.toLowerCase().includes(searchTerm)
          : (log.asset_name?.toLowerCase().includes(searchTerm) ||
             log.checked_out_by.toLowerCase().includes(searchTerm));
      })
      .sort((a, b) => {
        if (sortBy === 'apt_number') {
          const aValue = a.type === 'key' ? a.apt_number : a.asset_name || '';
          const bValue = b.type === 'key' ? b.apt_number : b.asset_name || '';
          return aValue.localeCompare(bValue, undefined, { numeric: true });
        }
        return new Date(b.return_time!).getTime() - new Date(a.return_time!).getTime();
      });
  }, [logs, searchQuery, sortBy]);

  const handleRevert = async (id: string) => {
    try {
      setRevertingId(id);
      await onRevert(id);
    } catch (error) {
      console.error('Error reverting key:', error);
    } finally {
      setRevertingId(null);
    }
  };

  if (filteredAndSortedLogs.length === 0) {
    return (
      <div className="text-center py-8 text-gray-500 bg-white rounded-lg border border-gray-200">
        No archived logs found
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg border border-gray-200 divide-y divide-gray-200">
      {filteredAndSortedLogs.map((log) => (
        <div key={log.id} className="p-4">
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center space-x-2">
                {log.type === 'key' ? (
                  <>
                    <KeyRound className="w-4 h-4 text-gray-400" />
                    <span className="font-medium">Apt #{log.apt_number}</span>
                  </>
                ) : (
                  <>
                    <Package className="w-4 h-4 text-gray-400" />
                    <span className="font-medium">{log.asset_name}</span>
                  </>
                )}
                <span className="text-gray-500">•</span>
                <span>{log.checked_out_by}</span>
              </div>
              <div className="mt-1 text-sm text-gray-500 space-y-1">
                <div>
                  Checked out: {new Date(log.checkout_time).toLocaleString()}
                </div>
                <div>
                  Returned: {new Date(log.return_time!).toLocaleString()}
                </div>
              </div>
              {log.reason && (
                <div className="mt-2 text-sm text-gray-600">
                  Reason: {log.reason}
                </div>
              )}
              {log.notes && (
                <div className="mt-1 text-sm text-gray-500">
                  Notes: {log.notes}
                </div>
              )}
            </div>
            <button
              onClick={() => handleRevert(log.id)}
              disabled={revertingId === log.id}
              className={`flex items-center px-3 py-1 text-sm text-blue-600 hover:bg-blue-50 rounded-md transition-colors ${
                revertingId === log.id ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              <RefreshCw className={`w-4 h-4 mr-1 ${revertingId === log.id ? 'animate-spin' : ''}`} />
              {revertingId === log.id ? 'Reverting...' : 'Revert'}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ArchivedKeyLogs;