import React from 'react';
import { Rocket } from 'lucide-react';

interface SidebarHeaderProps {
  isExpanded: boolean;
}

const SidebarHeader: React.FC<SidebarHeaderProps> = ({ isExpanded }) => {
  return (
    <div className="p-4 border-b border-marianBlue/20">
      <div className="flex items-center">
        <Rocket className="w-8 h-8 text-lightBlue" />
        {isExpanded && (
          <span className="ml-3 text-2xl font-bold text-white">MyOrbit</span>
        )}
      </div>
    </div>
  );
};

export default SidebarHeader;