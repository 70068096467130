import React, { memo } from 'react';
import { Archive } from 'lucide-react';
import CompactUnitCard from './CompactUnitCard';
import type { Unit } from '../../types/units';

interface UnitListProps {
  units: Unit[];
  expandedUnits: number[];
  onToggleExpand: (id: number) => void;
  onUnitChange: (id: number, field: keyof Unit, value: any) => void;
  onArchive: (unit: Unit) => void;
}

const UnitList = memo(({ 
  units, 
  expandedUnits, 
  onToggleExpand, 
  onUnitChange,
  onArchive
}: UnitListProps) => {
  if (units.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-8">
        <p className="text-center text-gray-500">No units found</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      {/* Category Labels */}
      <div className="grid grid-cols-4 gap-4 px-4 py-2 bg-gray-100 rounded-t-lg text-xs font-medium text-gray-700">
        <div>Apt #</div>
        <div>Move In</div>
        <div>Future Resident</div>
        <div className="flex justify-between">
          <span>Progress</span>
          <span className="mr-8">Actions</span>
        </div>
      </div>
      
      <div className="divide-y divide-gray-200">
        {units.map((unit) => (
          <div key={unit.id} className="relative">
            <div className="pr-16"> {/* Add padding to make room for archive button */}
              <CompactUnitCard
                unit={unit}
                isExpanded={expandedUnits.includes(unit.id)}
                onToggleExpand={() => onToggleExpand(unit.id)}
                onUnitChange={(field, value) => onUnitChange(unit.id, field, value)}
              />
            </div>
            {/* Archive Button - Absolutely positioned */}
            <button
              onClick={() => onArchive(unit)}
              className="absolute right-4 top-4 p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100 transition-colors"
              title="Archive unit"
            >
              <Archive className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
});

UnitList.displayName = 'UnitList';

export default UnitList;