import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import type { DailyTask } from '../../types/tasks';

interface QuickTaskEntryProps {
  onAddTask: (task: Omit<DailyTask, 'id' | 'property_id' | 'status' | 'created_at' | 'updated_at'>) => Promise<void>;
  scope: 'personal' | 'team';
}

const QuickTaskEntry: React.FC<QuickTaskEntryProps> = ({ onAddTask, scope }) => {
  const [title, setTitle] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim() || isSubmitting || !user?.email) return;

    try {
      setIsSubmitting(true);
      await onAddTask({
        title: title.trim(),
        type: 'daily',
        priority: 'medium',
        scope,
        completed: false,
        assignee: scope === 'personal' ? user.email : '' // Personal tasks always assigned to current user
      });
      setTitle('');
    } catch (error) {
      console.error('Error adding task:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4">
      <div className="flex items-center gap-2 p-3 bg-white rounded-lg border border-gray-200 focus-within:border-blue-500 transition-colors">
        <Plus className="w-5 h-5 text-gray-400" />
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={`Add a new ${scope} task...`}
          className="flex-1 outline-none text-gray-700 placeholder-gray-400"
          disabled={isSubmitting}
        />
        <button
          type="submit"
          disabled={!title.trim() || isSubmitting}
          className="px-3 py-1 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
        >
          {isSubmitting ? 'Adding...' : 'Add'}
        </button>
      </div>
    </form>
  );
};

export default QuickTaskEntry;