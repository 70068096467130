import React from 'react';

interface TaskTabsProps {
  activeTab: 'personal' | 'team';
  onTabChange: (tab: 'personal' | 'team') => void;
}

const TaskTabs: React.FC<TaskTabsProps> = ({ activeTab, onTabChange }) => {
  return (
    <div className="flex space-x-4 mb-4 border-b border-gray-200">
      <button
        onClick={() => onTabChange('personal')}
        className={`pb-2 px-1 ${
          activeTab === 'personal'
            ? 'border-b-2 border-blue-500 text-blue-600 font-medium'
            : 'text-gray-500 hover:text-gray-700'
        }`}
      >
        My Tasks
      </button>
      <button
        onClick={() => onTabChange('team')}
        className={`pb-2 px-1 ${
          activeTab === 'team'
            ? 'border-b-2 border-blue-500 text-blue-600 font-medium'
            : 'text-gray-500 hover:text-gray-700'
        }`}
      >
        Team Tasks
      </button>
    </div>
  );
};

export default TaskTabs;