import React from 'react';
import { useForm } from 'react-hook-form';
import { X } from 'lucide-react';
import DatePicker from 'react-datepicker';
import type { Unit } from '../types/units';

interface AddUnitModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddUnit: (unit: Omit<Unit, 'id'>) => Promise<void>;
}

const AddUnitModal: React.FC<AddUnitModalProps> = ({
  isOpen,
  onClose,
  onAddUnit
}) => {
  const { register, handleSubmit, setValue, watch, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      aptNumber: '',
      moveInDate: null,
      moveOutDate: null,
      futureResident: '',
      notes: '',
      bulkTrash: 'Not Started',
      paint: 'Not Started',
      carpetFlooring: 'Not Started',
      countertops: 'Not Started',
      cabinets: 'Not Started',
      tubReglaze: 'Not Started',
      appliances: 'Not Started',
      clean: 'Not Started'
    }
  });

  const handleFormSubmit = async (data: any) => {
    try {
      await onAddUnit(data);
      onClose();
    } catch (error) {
      console.error('Error adding unit:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">Add New Unit</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Apt #
              </label>
              <input
                type="text"
                {...register('aptNumber', { required: 'Apartment number is required' })}
                className="w-full px-3 py-2 border rounded-md"
              />
              {errors.aptNumber && (
                <p className="mt-1 text-sm text-red-600">{errors.aptNumber.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                M.I
              </label>
              <DatePicker
                selected={watch('moveInDate')}
                onChange={(date) => setValue('moveInDate', date)}
                dateFormat="MM/dd/yy"
                className="w-full px-3 py-2 border rounded-md"
                placeholderText="Select date"
                isClearable
              />
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                M.O
              </label>
              <DatePicker
                selected={watch('moveOutDate')}
                onChange={(date) => setValue('moveOutDate', date)}
                dateFormat="MM/dd/yy"
                className="w-full px-3 py-2 border rounded-md"
                placeholderText="Select date"
                isClearable
              />
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Future Resident
              </label>
              <input
                type="text"
                {...register('futureResident')}
                className="w-full px-3 py-2 border rounded-md"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {[
              { key: 'bulkTrash', label: 'Bulk Trash' },
              { key: 'paint', label: 'Paint' },
              { key: 'carpetFlooring', label: 'Carpet/Flooring' },
              { key: 'countertops', label: 'Countertops' },
              { key: 'cabinets', label: 'Cabinets' },
              { key: 'tubReglaze', label: 'Tub Reglaze' },
              { key: 'appliances', label: 'Appliances' },
              { key: 'clean', label: 'Clean' }
            ].map(({ key, label }) => (
              <div key={key}>
                <label className="block text-sm font-semibold text-gray-700 mb-1">
                  {label}
                </label>
                <select
                  {...register(key)}
                  className="w-full px-3 py-2 border rounded-md"
                >
                  <option value="Not Started">Not Started</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="Done">Done</option>
                </select>
              </div>
            ))}
          </div>

          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-1">
              Notes
            </label>
            <textarea
              {...register('notes')}
              rows={3}
              className="w-full px-3 py-2 border rounded-md"
            />
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isSubmitting ? 'Adding...' : 'Add Unit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUnitModal;