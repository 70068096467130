import React, { useState, useCallback } from 'react';
import { PlusCircle, Download, Search, Archive } from 'lucide-react';
import FilterBar from '../components/turn-board/FilterBar';
import ActionBar from '../components/turn-board/ActionBar';
import UnitList from '../components/turn-board/UnitList';
import ArchivedUnits from '../components/turn-board/ArchivedUnits';
import MobileTurnBoard from '../components/turn-board/MobileTurnBoard';
import AddUnitModal from '../components/AddUnitModal';
import ArchiveUnitModal from '../components/turn-board/ArchiveUnitModal';
import { downloadCSV } from '../utils/csvExport';
import useTurnBoard from '../hooks/useTurnBoard';
import useFilteredUnits from '../hooks/useFilteredUnits';
import { useProperty } from '../contexts/PropertyContext';
import type { Unit } from '../types/units';

const TurnBoard: React.FC = () => {
  const { selectedPropertyId } = useProperty();
  const { 
    units, 
    archivedUnits,
    isLoading, 
    error, 
    addUnit, 
    updateUnit,
    archiveUnit,
    restoreUnit,
    isEditing 
  } = useTurnBoard(selectedPropertyId);
  
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [expandedUnits, setExpandedUnits] = useState<number[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [sortBy, setSortBy] = useState<'aptNumber' | 'moveInDate'>('aptNumber');
  const [archiveModalData, setArchiveModalData] = useState<{
    isOpen: boolean;
    unit: Unit | null;
  }>({
    isOpen: false,
    unit: null
  });

  const filteredUnits = useFilteredUnits(
    showArchived ? archivedUnits : units,
    filter,
    sortBy
  );

  const handleAddUnit = useCallback(async (newUnit: Omit<Unit, 'id'>) => {
    try {
      await addUnit(newUnit);
      setIsAddModalOpen(false);
    } catch (err) {
      console.error('Failed to add unit:', err);
    }
  }, [addUnit]);

  const handleArchive = async (id: number, reason: string) => {
    try {
      await archiveUnit(id, reason);
      setArchiveModalData({ isOpen: false, unit: null });
    } catch (error) {
      console.error('Error archiving unit:', error);
    }
  };

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <h2 className="text-red-800 font-semibold mb-2">Error Loading Turn Board</h2>
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  if (!selectedPropertyId) {
    return (
      <div className="text-center py-8 text-gray-500">
        Please select a property from the menu to view its turn board
      </div>
    );
  }

  return (
    <div className="space-y-4 max-w-7xl mx-auto">
      <div className="flex flex-wrap items-center gap-4 justify-between">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold">Turn Board</h1>
          <button
            onClick={() => setShowArchived(!showArchived)}
            className={`flex items-center px-3 py-1 text-sm rounded-md transition-colors ${
              showArchived
                ? 'bg-gray-100 text-gray-700'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <Archive className="w-4 h-4 mr-1" />
            {showArchived ? 'Show Active' : 'Show Archived'}
          </button>
        </div>
        <FilterBar
          filter={filter}
          sortBy={sortBy}
          onFilterChange={setFilter}
          onSortChange={setSortBy}
          disabled={isEditing}
        />
        <ActionBar
          onExport={downloadCSV}
          onAddUnit={() => setIsAddModalOpen(true)}
          units={units}
          disabled={isEditing}
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-32">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <>
          {/* Desktop View */}
          <div className="hidden md:block">
            {showArchived ? (
              <ArchivedUnits
                units={filteredUnits}
                onRestore={restoreUnit}
                onUnitChange={updateUnit}
              />
            ) : (
              <UnitList
                units={filteredUnits}
                expandedUnits={expandedUnits}
                onToggleExpand={(id) => {
                  setExpandedUnits(prev =>
                    prev.includes(id) ? prev.filter(unitId => unitId !== id) : [...prev, id]
                  );
                }}
                onUnitChange={updateUnit}
                onArchive={(unit) => setArchiveModalData({ isOpen: true, unit })}
              />
            )}
          </div>

          {/* Mobile View */}
          <div className="md:hidden">
            <MobileTurnBoard
              units={filteredUnits}
              onUnitChange={updateUnit}
            />
          </div>
        </>
      )}

      <AddUnitModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAddUnit={handleAddUnit}
      />

      {archiveModalData.unit && (
        <ArchiveUnitModal
          isOpen={archiveModalData.isOpen}
          onClose={() => setArchiveModalData({ isOpen: false, unit: null })}
          onArchive={handleArchive}
          unit={archiveModalData.unit}
        />
      )}
    </div>
  );
};

export default TurnBoard;