import React from 'react';
import CircleChart from './CircleChart';

interface SummaryChartsProps {
  activeLeads: number;
  totalLeads: number;
  applications: number;
}

const SummaryCharts: React.FC<SummaryChartsProps> = ({
  activeLeads,
  totalLeads,
  applications
}) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
      <h2 className="text-lg font-semibold mb-6">Conversion Summary</h2>
      <div className="grid grid-cols-2 gap-8">
        <CircleChart
          value={activeLeads}
          total={totalLeads}
          label="Active Leads"
          color="stroke-blue-500"
        />
        <CircleChart
          value={applications}
          total={totalLeads}
          label="Lead to Application Rate"
          color="stroke-green-500"
        />
      </div>
    </div>
  );
};

export default SummaryCharts;