import { useState, useCallback, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import type { Unit } from '../types/units';

export const useTurnBoard = (propertyId: string | null) => {
  const [units, setUnits] = useState<Unit[]>([]);
  const [archivedUnits, setArchivedUnits] = useState<Unit[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const fetchUnits = useCallback(async () => {
    if (!propertyId) {
      setUnits([]);
      setArchivedUnits([]);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      // Fetch active units
      const { data: activeData, error: activeError } = await supabase
        .from('turn_board_units')
        .select('*')
        .eq('property_id', propertyId)
        .is('archived_at', null)
        .order('aptNumber', { ascending: true });

      if (activeError) throw activeError;

      // Fetch archived units
      const { data: archivedData, error: archivedError } = await supabase
        .from('turn_board_units')
        .select('*')
        .eq('property_id', propertyId)
        .not('archived_at', 'is', null)
        .order('archived_at', { ascending: false });

      if (archivedError) throw archivedError;

      setUnits(activeData || []);
      setArchivedUnits(archivedData || []);
    } catch (err) {
      console.error('Error fetching units:', err);
      setError('Failed to load units');
    } finally {
      setIsLoading(false);
    }
  }, [propertyId]);

  const addUnit = async (newUnit: Omit<Unit, 'id'>) => {
    if (!propertyId) throw new Error('No property selected');

    try {
      const { data, error } = await supabase
        .from('turn_board_units')
        .insert([{
          property_id: propertyId,
          aptNumber: newUnit.apt_number,
          moveInDate: newUnit.move_in_date,
          moveOutDate: newUnit.move_out_date,
          futureResident: newUnit.future_resident || '',
          notes: newUnit.notes || ''
        }])
        .select()
        .single();

      if (error) throw error;

      setUnits(prev => [...prev, data]);
      return data;
    } catch (err) {
      console.error('Error adding unit:', err);
      throw err;
    }
  };

  const updateUnit = async (id: number, updates: Partial<Unit>) => {
    if (!propertyId) throw new Error('No property selected');

    try {
      setIsEditing(true);

      // Convert field names to match database schema
      const dbUpdates: Record<string, any> = {};
      Object.entries(updates).forEach(([key, value]) => {
        // Handle special cases for field names
        const dbKey = key.replace(/_/g, '');
        dbUpdates[dbKey] = value;
      });

      const { data, error } = await supabase
        .from('turn_board_units')
        .update(dbUpdates)
        .eq('id', id)
        .eq('property_id', propertyId)
        .select()
        .single();

      if (error) throw error;

      setUnits(prev => prev.map(unit => 
        unit.id === id ? { ...unit, ...updates } : unit
      ));

      return data;
    } catch (err) {
      console.error('Error updating unit:', err);
      throw err;
    } finally {
      setIsEditing(false);
    }
  };

  const archiveUnit = async (id: number, reason: string) => {
    if (!propertyId) throw new Error('No property selected');

    try {
      const { data, error } = await supabase
        .from('turn_board_units')
        .update({
          archived_at: new Date().toISOString(),
          archive_reason: reason
        })
        .eq('id', id)
        .eq('property_id', propertyId)
        .select()
        .single();

      if (error) throw error;

      setUnits(prev => prev.filter(unit => unit.id !== id));
      setArchivedUnits(prev => [data, ...prev]);

      return data;
    } catch (err) {
      console.error('Error archiving unit:', err);
      throw err;
    }
  };

  const restoreUnit = async (id: number) => {
    if (!propertyId) throw new Error('No property selected');

    try {
      const { data, error } = await supabase
        .from('turn_board_units')
        .update({
          archived_at: null,
          archive_reason: null
        })
        .eq('id', id)
        .eq('property_id', propertyId)
        .select()
        .single();

      if (error) throw error;

      setArchivedUnits(prev => prev.filter(unit => unit.id !== id));
      setUnits(prev => [...prev, data]);

      return data;
    } catch (err) {
      console.error('Error restoring unit:', err);
      throw err;
    }
  };

  useEffect(() => {
    fetchUnits();

    if (propertyId) {
      const channel = supabase
        .channel('turn_board_changes')
        .on('postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'turn_board_units',
            filter: `property_id=eq.${propertyId}`
          },
          () => {
            fetchUnits();
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [propertyId, fetchUnits]);

  return {
    units,
    archivedUnits,
    isLoading,
    error,
    addUnit,
    updateUnit,
    archiveUnit,
    restoreUnit,
    isEditing
  };
};

export default useTurnBoard;