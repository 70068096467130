import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const usePagePersistence = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Save current path whenever it changes
    localStorage.setItem('lastPath', location.pathname);
  }, [location]);

  useEffect(() => {
    // Handle page visibility changes
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const lastPath = localStorage.getItem('lastPath');
        if (lastPath && lastPath !== location.pathname) {
          navigate(lastPath, { replace: true });
        }
      }
    };

    // Handle page focus
    const handleFocus = () => {
      const lastPath = localStorage.getItem('lastPath');
      if (lastPath && lastPath !== location.pathname) {
        navigate(lastPath, { replace: true });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, [navigate, location.pathname]);
};

export default usePagePersistence;