import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  LayoutDashboard,
  ListTodo,
  Users,
  ClipboardList,
  Building2,
  Key,
  Settings,
  LogOut
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import PropertySelector from './PropertySelector';

interface SidebarNavProps {
  isExpanded: boolean;
  onToggleExpand: () => void;
  isMobile?: boolean;
}

const SidebarNav = ({ 
  isExpanded, 
  onToggleExpand,
  isMobile = false 
}: SidebarNavProps) => {
  const { signOut } = useAuth();

  const navItems = [
    {
      path: '/overview',
      icon: LayoutDashboard,
      label: 'Overview',
      description: 'Property overview and metrics'
    },
    {
      path: '/tasks',
      icon: ListTodo,
      label: 'Tasks',
      description: 'Manage daily tasks'
    },
    {
      path: '/leads',
      icon: Users,
      label: 'Leads',
      description: 'Prospect management'
    },
    {
      path: '/applications',
      icon: ClipboardList,
      label: 'Applications',
      description: 'Rental applications'
    },
    {
      path: '/turn-board',
      icon: Building2,
      label: 'Turn Board',
      description: 'Unit turnover management'
    },
    {
      path: '/key-log',
      icon: Key,
      label: 'Key Log',
      description: 'Key tracking system'
    },
    {
      path: '/settings',
      icon: Settings,
      label: 'Settings',
      description: 'System preferences'
    }
  ];

  const baseItemClasses = `
    relative flex items-center px-3 py-2.5 rounded-lg
    transition-all duration-200 group font-semibold
  `;

  const activeItemClasses = `
    bg-sidebar-active text-lightBlue
  `;

  const inactiveItemClasses = `
    text-lightBlue/70 hover:bg-sidebar-hover hover:text-lightBlue
  `;

  return (
    <nav className="flex-1 overflow-y-auto py-4">
      <div className="px-3 mb-4">
        <PropertySelector />
      </div>
      
      <ul className="space-y-1 px-3">
        {navItems.map((item) => (
          <li key={item.path}>
            <NavLink
              to={item.path}
              className={({ isActive }) => `
                ${baseItemClasses}
                ${isActive ? activeItemClasses : inactiveItemClasses}
              `}
            >
              <item.icon className="w-6 h-6 flex-shrink-0" />
              {(isExpanded || isMobile) && (
                <span className="ml-3 whitespace-nowrap">{item.label}</span>
              )}
            </NavLink>
          </li>
        ))}
        
        <li className="mt-4 pt-4 border-t border-marianBlue/10">
          <button
            onClick={signOut}
            className={`
              w-full flex items-center px-3 py-2.5 rounded-lg
              transition-all duration-200 font-semibold
              text-lightBlue/70 hover:bg-sidebar-hover hover:text-lightBlue
            `}
          >
            <LogOut className="w-6 h-6 flex-shrink-0" />
            {(isExpanded || isMobile) && (
              <span className="ml-3 whitespace-nowrap">Sign Out</span>
            )}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default SidebarNav;