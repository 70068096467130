export interface PricingPlan {
  name: string;
  price: number;
  unitRange: string;
  features: string[];
  buttonText: string;
  trialDays: number;
}

export const pricingPlans: PricingPlan[] = [
  {
    name: 'Basic',
    price: 14.99,
    unitRange: '1-100',
    features: [
      'Manage up to 100 properties',
      'Basic task management',
      'Dashboard overview',
      'Add up to 5 collaborators',
      'Basic reporting'
    ],
    buttonText: 'Start Free Trial',
    trialDays: 14
  },
  {
    name: 'Standard',
    price: 24.99,
    unitRange: '101-200',
    features: [
      'Manage up to 200 properties',
      'Advanced task management',
      'Enhanced dashboard analytics',
      'Add up to 15 collaborators',
      'Detailed financial reports',
      'Integrations with accounting tools'
    ],
    buttonText: 'Start Free Trial',
    trialDays: 14
  },
  {
    name: 'Premium',
    price: 34.99,
    unitRange: '201+',
    features: [
      'Manage unlimited properties',
      'AI-driven task automation',
      'Real-time analytics and reporting',
      'Add up to 50 collaborators',
      'Comprehensive business intelligence',
      'Advanced ERP integrations',
      '24/7 premium support'
    ],
    buttonText: 'Start Free Trial',
    trialDays: 14
  }
];