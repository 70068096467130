import React from 'react';
import { ChevronRight } from 'lucide-react';
import { calculateUnitProgress } from '../../utils/unitProgress';
import { formatDate } from '../../utils/dateUtils';
import type { Unit } from '../../types/units';

interface MobileUnitCardProps {
  unit: Unit;
  onSelect: (unit: Unit) => void;
}

const MobileUnitCard: React.FC<MobileUnitCardProps> = ({ unit, onSelect }) => {
  const { progress } = calculateUnitProgress(unit);

  return (
    <button
      onClick={() => onSelect(unit)}
      className="w-full bg-white p-4 rounded-lg border border-gray-200 shadow-sm mb-2"
    >
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <div className="flex items-center justify-between mb-1">
            <span className="font-bold text-lg">#{unit.aptNumber}</span>
            <span className="text-sm text-gray-600">
              {unit.moveInDate ? formatDate(unit.moveInDate) : '—'}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex-1 h-2 bg-gray-200 rounded-full">
              <div
                className="h-2 bg-blue-600 rounded-full transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
            <span className="text-sm text-gray-600 min-w-[40px] text-right">
              {Math.round(progress)}%
            </span>
          </div>
        </div>
        <ChevronRight className="w-5 h-5 text-gray-400 ml-4" />
      </div>
    </button>
  );
};

export default MobileUnitCard;