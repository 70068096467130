import React, { useState } from 'react';
import { Plus, Loader2 } from 'lucide-react';
import useProperties from '../../hooks/useProperties';
import PropertyForm from './PropertyForm';

const AddPropertyButton: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { createProperty, isCreating } = useProperties();

  const handleCreateProperty = async (name: string, location: string) => {
    try {
      await createProperty(name, location);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error creating property:', error);
    }
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        disabled={isCreating}
      >
        {isCreating ? (
          <Loader2 className="w-5 h-5 mr-2 animate-spin" />
        ) : (
          <Plus className="w-5 h-5 mr-2" />
        )}
        Add Property
      </button>

      <PropertyForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleCreateProperty}
        isSubmitting={isCreating}
      />
    </>
  );
};

export default AddPropertyButton;