import type { Unit } from '../types/units';

export const calculateUnitProgress = (unit: Unit) => {
  const fields = [
    'bulkTrash',
    'paint',
    'carpetFlooring',
    'countertops',
    'cabinets',
    'tubReglaze',
    'appliances',
    'clean'
  ];

  const completedTasks = fields.filter(field => unit[field as keyof Unit] === 'Done').length;
  const progress = (completedTasks / fields.length) * 100;
  const isComplete = progress === 100;

  return {
    progress,
    isComplete,
    completedTasks,
    totalTasks: fields.length
  };
};

export const getStatusFields = () => [
  { key: 'bulkTrash', label: 'Bulk Trash' },
  { key: 'paint', label: 'Paint' },
  { key: 'carpetFlooring', label: 'Carpet/Flooring' },
  { key: 'countertops', label: 'Countertops' },
  { key: 'cabinets', label: 'Cabinets' },
  { key: 'tubReglaze', label: 'Tub Reglaze' },
  { key: 'appliances', label: 'Appliances' },
  { key: 'clean', label: 'Clean' }
];