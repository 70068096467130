import React, { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { useTaskManagement } from '../hooks/useTaskManagement';
import { useProperty } from '../contexts/PropertyContext';
import TaskList from '../components/tasks/TaskList';
import TaskTabs from '../components/tasks/TaskTabs';
import QuickTaskEntry from '../components/tasks/QuickTaskEntry';
import type { TaskScope } from '../types/tasks';

const Tasks: React.FC = () => {
  const { selectedPropertyId } = useProperty();
  const {
    tasks,
    isLoading,
    error,
    addTask,
    updateTask,
    deleteTask,
    reorderTasks
  } = useTaskManagement(selectedPropertyId);

  const [activeTab, setActiveTab] = useState<TaskScope>('personal');

  const handleReorderTasks = (startIndex: number, endIndex: number) => {
    if (startIndex === endIndex) return;
    reorderTasks(startIndex, endIndex);
  };

  if (!selectedPropertyId) {
    return (
      <div className="text-center py-8 text-gray-500">
        Please select a property from the menu to view tasks
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <p className="text-red-800">{error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto space-y-6">
      <h1 className="text-2xl font-bold">Tasks</h1>

      <TaskTabs
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />

      <QuickTaskEntry 
        onAddTask={addTask}
        scope={activeTab}
      />

      {isLoading ? (
        <div className="flex justify-center items-center h-32">
          <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
        </div>
      ) : (
        <TaskList
          tasks={tasks}
          onUpdateTask={updateTask}
          onDeleteTask={deleteTask}
          onReorderTasks={handleReorderTasks}
          scope={activeTab}
        />
      )}
    </div>
  );
};

export default Tasks;