import React from 'react';
import { RefreshCw } from 'lucide-react';
import CompactUnitCard from './CompactUnitCard';
import type { Unit } from '../../types/units';

interface ArchivedUnitsProps {
  units: Unit[];
  onRestore: (id: number) => Promise<void>;
  onUnitChange: (id: number, field: keyof Unit, value: any) => void;
}

const ArchivedUnits: React.FC<ArchivedUnitsProps> = ({
  units,
  onRestore,
  onUnitChange
}) => {
  const [expandedUnits, setExpandedUnits] = React.useState<number[]>([]);
  const [isRestoring, setIsRestoring] = React.useState<number | null>(null);

  const handleRestore = async (id: number) => {
    try {
      setIsRestoring(id);
      await onRestore(id);
    } catch (error) {
      console.error('Error restoring unit:', error);
    } finally {
      setIsRestoring(null);
    }
  };

  const handleToggleExpand = (id: number) => {
    setExpandedUnits(prev =>
      prev.includes(id) ? prev.filter(unitId => unitId !== id) : [...prev, id]
    );
  };

  if (units.length === 0) {
    return (
      <div className="text-center py-8 text-gray-500 bg-white rounded-lg border border-gray-200">
        No archived units found
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {units.map((unit) => (
        <div key={unit.id} className="bg-white rounded-lg border border-gray-200">
          <CompactUnitCard
            unit={unit}
            isExpanded={expandedUnits.includes(unit.id)}
            onToggleExpand={() => handleToggleExpand(unit.id)}
            onUnitChange={onUnitChange}
          />
          <div className="px-4 py-3 bg-gray-50 border-t border-gray-200">
            <button
              onClick={() => handleRestore(unit.id)}
              disabled={isRestoring === unit.id}
              className="flex items-center text-blue-600 hover:text-blue-700"
            >
              <RefreshCw className={`w-4 h-4 mr-2 ${isRestoring === unit.id ? 'animate-spin' : ''}`} />
              {isRestoring === unit.id ? 'Restoring...' : 'Restore Unit'}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ArchivedUnits;