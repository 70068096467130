import React, { useState } from 'react';
import { X, Search } from 'lucide-react';
import MobileUnitCard from './MobileUnitCard';
import MobileUnitDetail from './MobileUnitDetail';
import type { Unit } from '../../types/units';

interface MobileTurnBoardProps {
  units: Unit[];
  onUnitChange: (id: number, field: keyof Unit, value: any) => void;
}

const MobileTurnBoard: React.FC<MobileTurnBoardProps> = ({ units, onUnitChange }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);

  const filteredUnits = units.filter(unit => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (unit.aptNumber || '').toLowerCase().includes(searchLower) ||
      (unit.futureResident || '').toLowerCase().includes(searchLower)
    );
  });

  if (selectedUnit) {
    return (
      <div className="fixed inset-0 bg-white z-50 overflow-auto">
        <div className="sticky top-0 bg-white border-b border-gray-200 p-4 flex items-center justify-between">
          <h2 className="text-lg font-semibold">Unit #{selectedUnit.aptNumber}</h2>
          <button
            onClick={() => setSelectedUnit(null)}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        <div className="p-4">
          <MobileUnitDetail
            unit={selectedUnit}
            onUnitChange={(field, value) => onUnitChange(selectedUnit.id, field, value)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="md:hidden">
      <div className="sticky top-0 bg-white p-4 border-b border-gray-200">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search units..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>
      <div className="p-4">
        {filteredUnits.map((unit) => (
          <MobileUnitCard
            key={unit.id}
            unit={unit}
            onSelect={setSelectedUnit}
          />
        ))}
        {filteredUnits.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No units found
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileTurnBoard;