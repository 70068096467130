import React, { useState, useRef, useCallback } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Input, Dropdown } from '../ui/TurnBoardComponents';
import DatePicker from 'react-datepicker';
import type { Unit } from '../../types/units';

interface CompactUnitCardProps {
  unit: Unit;
  isExpanded: boolean;
  onToggleExpand: () => void;
  onUnitChange: (field: keyof Unit, value: any) => void;
}

const CompactUnitCard: React.FC<CompactUnitCardProps> = ({ unit, isExpanded, onToggleExpand, onUnitChange }) => {
  const cardRef = useRef<HTMLDivElement>(null);

  const handleInputChange = useCallback((field: keyof Unit, value: any) => {
    // Store current scroll position before update
    const scrollPosition = window.scrollY;
    onUnitChange(field, value);
    // Restore scroll position after a short delay to ensure DOM has updated
    requestAnimationFrame(() => {
      window.scrollTo(0, scrollPosition);
    });
  }, [onUnitChange]);

  const calculateProgress = () => {
    const fields = ['bulkTrash', 'paint', 'carpetFlooring', 'countertops', 'cabinets', 'tubReglaze', 'appliances', 'clean'];
    const completedTasks = fields.filter(field => unit[field as keyof Unit] === 'Done').length;
    return (completedTasks / fields.length) * 100;
  };

  const progress = calculateProgress();
  const statusFields = ['bulkTrash', 'paint', 'carpetFlooring', 'countertops', 'cabinets', 'tubReglaze', 'appliances', 'clean'];

  return (
    <div className="p-4" ref={cardRef}>
      <div className="flex items-center gap-4">
        <div className="grid grid-cols-4 gap-4 flex-1">
          {!isExpanded ? (
            <>
              <div className="py-2 px-3 bg-gray-50 rounded-md text-gray-700">
                {unit.aptNumber || '—'}
              </div>
              <div className="py-2 px-3 bg-gray-50 rounded-md text-gray-700">
                {unit.moveInDate ? new Date(unit.moveInDate).toLocaleDateString() : '—'}
              </div>
              <div className="py-2 px-3 bg-gray-50 rounded-md text-gray-700 truncate">
                {unit.futureResident || '—'}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 flex-1">
                  <div className="flex-1 h-2 bg-gray-200 rounded-full">
                    <div
                      className="h-2 bg-blue-600 rounded-full transition-all duration-300"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                  <span className="text-xs text-gray-500 whitespace-nowrap">{Math.round(progress)}%</span>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onToggleExpand();
                  }}
                  className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                >
                  {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </button>
              </div>
            </>
          ) : (
            <>
              <div>
                <Input
                  type="text"
                  value={unit.aptNumber}
                  onChange={(e) => handleInputChange('aptNumber', e.target.value)}
                  placeholder="Apt #"
                />
              </div>
              <div>
                <DatePicker
                  selected={unit.moveInDate ? new Date(unit.moveInDate) : null}
                  onChange={(date: Date) => handleInputChange('moveInDate', date.toISOString().split('T')[0])}
                  dateFormat="MM/dd/yy"
                  placeholderText="Move-in Date"
                  className="w-full border rounded-md px-3 py-2"
                />
              </div>
              <div>
                <Input
                  type="text"
                  value={unit.futureResident}
                  onChange={(e) => handleInputChange('futureResident', e.target.value)}
                  placeholder="Future Resident"
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 flex-1">
                  <div className="flex-1 h-2 bg-gray-200 rounded-full">
                    <div
                      className="h-2 bg-blue-600 rounded-full transition-all duration-300"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                  <span className="text-xs text-gray-500 whitespace-nowrap">{Math.round(progress)}%</span>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onToggleExpand();
                  }}
                  className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                >
                  {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      {isExpanded && (
        <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-4">
          {statusFields.map((field) => (
            <div key={field}>
              <label className="block text-xs font-medium text-gray-700 mb-1">
                {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
              </label>
              <Dropdown
                value={unit[field as keyof Unit] as string}
                onChange={(e) => handleInputChange(field as keyof Unit, e.target.value)}
                options={['Not Started', 'Scheduled', 'Done']}
                onVendorChange={(value) => handleInputChange(`${field}Vendor` as keyof Unit, value)}
                onScheduledDateChange={(date) => handleInputChange(`${field}Scheduled` as keyof Unit, date?.toISOString().split('T')[0])}
                onCostChange={(value) => handleInputChange(`${field}Cost` as keyof Unit, value)}
                vendorName={unit[`${field}Vendor` as keyof Unit] as string}
                scheduledDate={unit[`${field}Scheduled` as keyof Unit] as string}
                estimatedCost={unit[`${field}Cost` as keyof Unit] as number}
              />
            </div>
          ))}
          <div className="col-span-2 sm:col-span-4">
            <label className="block text-xs font-medium text-gray-700 mb-1">Notes</label>
            <textarea
              value={unit.notes}
              onChange={(e) => handleInputChange('notes', e.target.value)}
              className="w-full p-2 border rounded text-sm"
              rows={2}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CompactUnitCard;