import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import TaskItem from './TaskItem';
import { useAuth } from '../../contexts/AuthContext';
import type { DailyTask } from '../../types/tasks';

interface TaskListProps {
  tasks: DailyTask[];
  onUpdateTask: (id: string, updates: Partial<DailyTask>) => Promise<void>;
  onDeleteTask: (id: string) => Promise<void>;
  onReorderTasks?: (startIndex: number, endIndex: number) => void;
  scope: 'personal' | 'team';
}

const TaskList: React.FC<TaskListProps> = ({ 
  tasks, 
  onUpdateTask, 
  onDeleteTask,
  onReorderTasks,
  scope 
}) => {
  const { user } = useAuth();

  // Filter tasks based on scope and ownership
  const filteredTasks = tasks.filter(task => {
    if (scope === 'personal') {
      // Only show personal tasks belonging to the current user
      return task.scope === 'personal' && task.assignee === user?.email;
    } else {
      // Show all team tasks
      return task.scope === 'team';
    }
  });

  const handleDragEnd = (result: any) => {
    if (!result.destination || !onReorderTasks) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    onReorderTasks(sourceIndex, destinationIndex);
  };

  if (filteredTasks.length === 0) {
    return (
      <div className="text-center py-8 text-gray-500 bg-white rounded-lg border border-gray-200">
        No tasks found. Click "Add Task" to create one.
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="tasks">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="space-y-2"
          >
            {filteredTasks.map((task, index) => (
              <Draggable 
                key={task.id} 
                draggableId={String(task.id)}
                index={index}
                isDragDisabled={task.completed}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`transform transition-all duration-200 ${
                      snapshot.isDragging ? 'rotate-2 scale-105 shadow-lg' : ''
                    }`}
                  >
                    <div {...provided.dragHandleProps}>
                      <TaskItem
                        task={task}
                        onUpdate={(updates) => onUpdateTask(task.id, updates)}
                        onDelete={() => onDeleteTask(task.id)}
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TaskList;