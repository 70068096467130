import React, { useState } from 'react';
import { Input, Dropdown } from '../ui/TurnBoardComponents';
import DatePicker from 'react-datepicker';
import { getStatusFields } from '../../utils/unitProgress';
import type { Unit } from '../../types/units';

interface MobileUnitDetailProps {
  unit: Unit;
  onUnitChange: (field: keyof Unit, value: any) => void;
}

const MobileUnitDetail: React.FC<MobileUnitDetailProps> = ({ unit, onUnitChange }) => {
  const statusFields = getStatusFields();
  const [isDirty, setIsDirty] = useState(false);
  const [changes, setChanges] = useState<Partial<Unit>>({});

  const handleChange = (field: keyof Unit, value: any) => {
    setChanges(prev => ({ ...prev, [field]: value }));
    setIsDirty(true);
  };

  const handleSave = () => {
    Object.entries(changes).forEach(([field, value]) => {
      onUnitChange(field as keyof Unit, value);
    });
    setChanges({});
    setIsDirty(false);
  };

  return (
    <div className="space-y-6 pb-20">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Move In Date
          </label>
          <DatePicker
            selected={unit.moveInDate ? new Date(unit.moveInDate) : null}
            onChange={(date) => handleChange('moveInDate', date?.toISOString().split('T')[0])}
            dateFormat="MM/dd/yy"
            className="w-full border rounded-md px-3 py-2"
            placeholderText="Select date"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Future Resident
          </label>
          <Input
            type="text"
            value={unit.futureResident || ''}
            onChange={(e) => handleChange('futureResident', e.target.value)}
            placeholder="Enter name"
          />
        </div>
      </div>

      <div className="space-y-4">
        {statusFields.map(({ key, label }) => (
          <div key={key}>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {label}
            </label>
            <Dropdown
              value={unit[key as keyof Unit] as string}
              onChange={(e) => handleChange(key as keyof Unit, e.target.value)}
              options={['Not Started', 'Scheduled', 'Done']}
              onVendorChange={(value) => handleChange(`${key}Vendor` as keyof Unit, value)}
              onScheduledDateChange={(date) => handleChange(`${key}Scheduled` as keyof Unit, date?.toISOString().split('T')[0])}
              onCostChange={(value) => handleChange(`${key}Cost` as keyof Unit, value)}
              vendorName={unit[`${key}Vendor` as keyof Unit] as string}
              scheduledDate={unit[`${key}Scheduled` as keyof Unit] as string}
              estimatedCost={unit[`${key}Cost` as keyof Unit] as number}
            />
          </div>
        ))}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Notes
        </label>
        <textarea
          value={unit.notes || ''}
          onChange={(e) => handleChange('notes', e.target.value)}
          className="w-full p-2 border rounded-md text-sm"
          rows={3}
          placeholder="Add notes..."
        />
      </div>

      {/* Fixed Save Button */}
      {isDirty && (
        <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200">
          <button
            onClick={handleSave}
            className="w-full px-4 py-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors"
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default MobileUnitDetail;