import React, { useState } from 'react';
import { Plus, KeyRound } from 'lucide-react';
import useProperties from '../hooks/useProperties';
import PropertyForm from './settings/PropertyForm';
import JoinPropertyForm from './collaboration/JoinPropertyForm';

const WelcomeScreen: React.FC = () => {
  const [mode, setMode] = useState<'create' | 'join' | null>(null);
  const { createProperty, isCreating } = useProperties();

  return (
    <div className="min-h-[80vh] flex items-center justify-center">
      <div className="max-w-md w-full space-y-8 p-6">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900">Welcome to MyOrbit</h2>
          <p className="mt-2 text-gray-600">
            Get started by creating a new property or joining an existing one
          </p>
        </div>

        {!mode && (
          <div className="grid grid-cols-1 gap-4">
            <button
              onClick={() => setMode('create')}
              className="flex items-center justify-center px-6 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Plus className="w-5 h-5 mr-2" />
              Create New Property
            </button>
            <button
              onClick={() => setMode('join')}
              className="flex items-center justify-center px-6 py-4 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200 transition-colors"
            >
              <KeyRound className="w-5 h-5 mr-2" />
              Join Existing Property
            </button>
          </div>
        )}

        {mode === 'create' && (
          <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-200">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Create Property</h3>
              <button
                onClick={() => setMode(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                Cancel
              </button>
            </div>
            <PropertyForm
              isOpen={true}
              onClose={() => setMode(null)}
              onSubmit={createProperty}
              isSubmitting={isCreating}
            />
          </div>
        )}

        {mode === 'join' && (
          <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-200">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Join Property</h3>
              <button
                onClick={() => setMode(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                Cancel
              </button>
            </div>
            <JoinPropertyForm />
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomeScreen;