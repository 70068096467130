import { useState, useCallback, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import type { DailyTask } from '../types/tasks';

export const useTaskManagement = (propertyId: string | null) => {
  const [tasks, setTasks] = useState<DailyTask[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const fetchTasks = useCallback(async () => {
    if (!propertyId || !user?.email) {
      setTasks([]);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const { data, error: fetchError } = await supabase
        .from('tasks')
        .select('*')
        .eq('property_id', propertyId)
        .or(`scope.eq.team,and(scope.eq.personal,assignee.eq.${user.email})`)
        .order('order', { ascending: true });

      if (fetchError) throw fetchError;
      setTasks(data || []);
    } catch (err) {
      console.error('Error fetching tasks:', err);
      setError('Failed to load tasks');
    } finally {
      setIsLoading(false);
    }
  }, [propertyId, user?.email]);

  const addTask = async (task: Omit<DailyTask, 'id' | 'property_id' | 'status' | 'created_at' | 'updated_at' | 'order'>) => {
    if (!propertyId || !user?.email) throw new Error('No property or user');

    try {
      // Get the highest order value for the scope
      const { data: maxOrderData } = await supabase
        .from('tasks')
        .select('order')
        .eq('property_id', propertyId)
        .eq('scope', task.scope)
        .order('order', { ascending: false })
        .limit(1)
        .single();

      const newOrder = (maxOrderData?.order ?? -1) + 1;

      const { data, error } = await supabase
        .from('tasks')
        .insert([{
          ...task,
          property_id: propertyId,
          assignee: task.scope === 'personal' ? user.email : task.assignee,
          status: 'active',
          order: newOrder,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (error) throw error;
      setTasks(prev => [...prev, data]);
      return data;
    } catch (err) {
      console.error('Error adding task:', err);
      throw err;
    }
  };

  const updateTask = async (id: string, updates: Partial<DailyTask>) => {
    if (!propertyId) throw new Error('No property selected');

    try {
      const { data, error } = await supabase
        .from('tasks')
        .update({
          ...updates,
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .eq('property_id', propertyId)
        .select()
        .single();

      if (error) throw error;
      setTasks(prev => prev.map(task => task.id === id ? data : task));
      return data;
    } catch (err) {
      console.error('Error updating task:', err);
      throw err;
    }
  };

  const deleteTask = async (id: string) => {
    if (!propertyId) throw new Error('No property selected');

    try {
      const { error } = await supabase
        .from('tasks')
        .delete()
        .eq('id', id)
        .eq('property_id', propertyId);

      if (error) throw error;
      setTasks(prev => prev.filter(task => task.id !== id));
    } catch (err) {
      console.error('Error deleting task:', err);
      throw err;
    }
  };

  const reorderTasks = async (startIndex: number, endIndex: number) => {
    if (!propertyId || startIndex === endIndex) return;

    try {
      // Get the task being moved
      const taskToMove = tasks[startIndex];
      if (!taskToMove) return;

      // Create new array with reordered tasks
      const reorderedTasks = Array.from(tasks);
      reorderedTasks.splice(startIndex, 1);
      reorderedTasks.splice(endIndex, 0, taskToMove);

      // Update local state immediately for smooth UI
      setTasks(reorderedTasks);

      // Calculate new order values
      const updates = reorderedTasks.map((task, index) => ({
        id: task.id,
        order: index,
        updated_at: new Date().toISOString()
      }));

      // Update all tasks in a single transaction
      const { error } = await supabase.rpc('reorder_tasks', {
        p_property_id: propertyId,
        p_task_updates: updates
      });

      if (error) {
        // If error occurs, revert to original order
        console.error('Error reordering tasks:', error);
        await fetchTasks(); // Refresh tasks from server
        throw error;
      }
    } catch (err) {
      console.error('Error reordering tasks:', err);
      // Revert to original order on error
      await fetchTasks();
      throw err;
    }
  };

  useEffect(() => {
    fetchTasks();

    if (propertyId) {
      const channel = supabase
        .channel('tasks_changes')
        .on('postgres_changes', 
          { 
            event: '*', 
            schema: 'public',
            table: 'tasks',
            filter: `property_id=eq.${propertyId}`
          }, 
          () => {
            fetchTasks();
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [propertyId, fetchTasks]);

  return {
    tasks,
    isLoading,
    error,
    addTask,
    updateTask,
    deleteTask,
    reorderTasks,
    refreshTasks: fetchTasks
  };
};

export default useTaskManagement;