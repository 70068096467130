import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarNav from './SidebarNav';
import MobileNav from './MobileNav';
import SidebarHeader from './SidebarHeader';

const Sidebar: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setIsExpanded(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  return (
    <>
      {/* Desktop Sidebar */}
      <aside
        className={`
          hidden md:block fixed left-0 top-0 h-screen 
          bg-gradient-to-b from-[#1c7293] to-[#21295c]
          rounded-r-xl shadow-lg
          transition-all duration-300 ease-in-out z-40
          ${isExpanded ? 'w-64' : 'w-20'}
        `}
        style={{
          WebkitBackgroundClip: 'padding-box',
          backgroundClip: 'padding-box'
        }}
      >
        <div className="flex flex-col h-full p-4">
          <SidebarHeader isExpanded={isExpanded} />
          <SidebarNav 
            isExpanded={isExpanded} 
            onToggleExpand={() => setIsExpanded(!isExpanded)} 
          />
        </div>
      </aside>

      {/* Mobile Navigation */}
      <MobileNav 
        isOpen={isMobileMenuOpen}
        onToggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      />
    </>
  );
};

export default Sidebar;