import React, { useState } from 'react';
import { Trash2, Edit2, Save, X, GripVertical } from 'lucide-react';
import type { DailyTask } from '../../types/tasks';

interface TaskItemProps {
  task: DailyTask;
  onUpdate: (updates: Partial<DailyTask>) => Promise<void>;
  onDelete: () => Promise<void>;
}

const TaskItem: React.FC<TaskItemProps> = ({ task, onUpdate, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(task.title);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSave = async () => {
    if (!editedTitle.trim()) return;
    
    try {
      setIsLoading(true);
      setError(null);
      await onUpdate({ title: editedTitle.trim() });
      setIsEditing(false);
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to update task';
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleComplete = async () => {
    try {
      setIsLoading(true);
      setError(null);
      await onUpdate({ 
        completed: !task.completed,
        completed_at: !task.completed ? new Date().toISOString() : null
      });
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to update task';
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`bg-white rounded-lg border ${
      task.completed ? 'border-gray-200 opacity-75' : 'border-gray-200'
    } p-3`}>
      {error && (
        <div className="text-sm text-red-600 mb-2 bg-red-50 p-2 rounded">
          {error}
        </div>
      )}
      
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3 flex-1">
          <div className="cursor-grab active:cursor-grabbing text-gray-400">
            <GripVertical className="w-5 h-5" />
          </div>
          <input
            type="checkbox"
            checked={task.completed}
            onChange={handleComplete}
            disabled={isLoading}
            className="w-5 h-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          
          {isEditing ? (
            <input
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              className="flex-1 px-2 py-1 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              autoFocus
            />
          ) : (
            <span className={`flex-1 ${task.completed ? 'line-through text-gray-500' : ''}`}>
              {task.title}
            </span>
          )}
        </div>

        <div className="flex items-center space-x-2">
          {isEditing ? (
            <>
              <button
                onClick={handleSave}
                disabled={isLoading || !editedTitle.trim()}
                className="p-1 text-green-600 hover:text-green-700 disabled:opacity-50"
              >
                <Save className="w-4 h-4" />
              </button>
              <button
                onClick={() => {
                  setIsEditing(false);
                  setEditedTitle(task.title);
                  setError(null);
                }}
                disabled={isLoading}
                className="p-1 text-gray-600 hover:text-gray-700 disabled:opacity-50"
              >
                <X className="w-4 h-4" />
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => setIsEditing(true)}
                disabled={task.completed || isLoading}
                className="p-1 text-blue-600 hover:text-blue-700 disabled:opacity-50"
              >
                <Edit2 className="w-4 h-4" />
              </button>
              <button
                onClick={onDelete}
                disabled={isLoading}
                className="p-1 text-red-600 hover:text-red-700 disabled:opacity-50"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskItem;