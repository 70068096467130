import React from 'react';
import { useProperty } from '../contexts/PropertyContext';
import useTurnBoard from '../hooks/useTurnBoard';
import useLeads from '../hooks/useLeads';
import useApplications from '../hooks/useApplications';
import useKeyLog from '../hooks/useKeyLog';
import { Building2, Users, ClipboardList, Key } from 'lucide-react';
import SummaryCharts from '../components/overview/SummaryCharts';
import WelcomeScreen from '../components/WelcomeScreen';

const Overview: React.FC = () => {
  const { selectedPropertyId, properties } = useProperty();
  const { units } = useTurnBoard(selectedPropertyId);
  const { leads } = useLeads();
  const { applications } = useApplications();
  const { keyLogs } = useKeyLog();

  // Show welcome screen if user has no properties
  if (properties.length === 0) {
    return <WelcomeScreen />;
  }

  // Only count active leads
  const activeLeads = leads.filter(lead => lead.status === 'active');
  
  // Count keys that are currently checked out
  const activeKeyLogs = keyLogs.filter(log => log.status === 'checked_out');

  if (!selectedPropertyId) {
    return (
      <div className="text-center py-8 text-gray-500">
        Please select a property from the menu to view overview
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold">Overview</h1>

      {/* Quick Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <div className="flex items-center">
            <div className="p-2 bg-blue-100 rounded-lg">
              <Building2 className="w-6 h-6 text-blue-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-500">Units</p>
              <p className="text-xl font-semibold">{units.length}</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <div className="flex items-center">
            <div className="p-2 bg-green-100 rounded-lg">
              <Users className="w-6 h-6 text-green-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-500">Active Leads</p>
              <p className="text-xl font-semibold">{activeLeads.length}</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <div className="flex items-center">
            <div className="p-2 bg-yellow-100 rounded-lg">
              <ClipboardList className="w-6 h-6 text-yellow-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-500">Applications</p>
              <p className="text-xl font-semibold">{applications.length}</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <div className="flex items-center">
            <div className="p-2 bg-purple-100 rounded-lg">
              <Key className="w-6 h-6 text-purple-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-500">Keys Out</p>
              <p className="text-xl font-semibold">{activeKeyLogs.length}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Summary Charts */}
      <SummaryCharts
        activeLeads={activeLeads.length}
        totalLeads={leads.length}
        applications={applications.length}
      />
    </div>
  );
};

export default Overview;