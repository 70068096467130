import React from 'react';

interface CircleChartProps {
  value: number;
  total: number;
  label: string;
  color: string;
}

const CircleChart: React.FC<CircleChartProps> = ({ value, total, label, color }) => {
  const percentage = total > 0 ? (value / total) * 100 : 0;
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="flex flex-col items-center">
      <div className="relative">
        <svg width="100" height="100" className="transform -rotate-90">
          {/* Background circle */}
          <circle
            cx="50"
            cy="50"
            r={radius}
            className="stroke-gray-200"
            strokeWidth="8"
            fill="none"
          />
          {/* Progress circle */}
          <circle
            cx="50"
            cy="50"
            r={radius}
            className={color}
            strokeWidth="8"
            fill="none"
            strokeLinecap="round"
            style={{
              strokeDasharray: circumference,
              strokeDashoffset
            }}
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-lg font-semibold">{Math.round(percentage)}%</span>
        </div>
      </div>
      <p className="mt-2 text-sm text-gray-600">{label}</p>
      <p className="text-sm font-medium">
        {value} / {total}
      </p>
    </div>
  );
};

export default CircleChart;