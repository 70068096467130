import React from 'react';
import { Building2 } from 'lucide-react';
import useProperties from '../hooks/useProperties';
import { useProperty } from '../contexts/PropertyContext';
import PropertyList from '../components/properties/PropertyList';
import AddPropertyButton from '../components/settings/AddPropertyButton';
import CollaborationSettings from '../components/settings/CollaborationSettings';

const WorkspaceSettings: React.FC = () => {
  const { selectedPropertyId } = useProperty();
  const { properties, isLoading: propertiesLoading } = useProperties();

  if (!selectedPropertyId) {
    return (
      <div className="text-center py-8 text-gray-500">
        Please select a property to manage settings
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto space-y-8 pb-8">
      <h1 className="text-2xl font-bold">Settings</h1>

      {/* Property Management */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <Building2 className="w-5 h-5 text-blue-600 mr-2" />
            <h2 className="text-xl font-semibold">Manage Properties</h2>
          </div>
          <AddPropertyButton />
        </div>

        {propertiesLoading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <PropertyList />
        )}
      </div>

      {/* Collaboration Settings */}
      <CollaborationSettings />
    </div>
  );
};

export default WorkspaceSettings;